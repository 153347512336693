<template>
  <div test="addresses-crud">
    <div class="uk-flex uk-flex-between uk-flex-middle mt-8">
      <div class="uk-text-large font-bold">Direcciones</div>
      <div>
        <button
          class="uk-button uk-button-primary uk-border-rounded"
          href="#edit-address-modal"
          uk-toggle
          @click="setNewMode"
        >
          Agregar nueva dirección
        </button>
      </div>
    </div>

    <div class="mt-6">
      <div v-for="(address, index) in addresses" :key="address.id">
        <div
            class="flex border rounded-2xl mb-6 shadow"
            :class="{ 'bg-gray-100': address.default }"
        >
          <div
              class="flex items-center justify-center bg-gray-900 mr-4 p-4 w-16 font-bold text-white rounded-l-2xl"
          >
             <div>
               {{ index + 1 }}
             </div>
          </div>
          <div class="flex-1 p-4">
            <div class="uk-text-bold uk-text-large">
              {{ address.first_name }} {{ address.last_name }}
            </div>
            <div>
              <span class="uk-text-bold">Teléfono:</span>
              {{ address.phone }}
            </div>
            <div>
              <span class="uk-text-bold">Dirección:</span>
              {{ address.address }}, Zona
              {{ address.zone }}
            </div>
            <div>
              {{ address.city.name }}, {{ address.city.state.name }},
              {{ address.city.state.country.spanish }}
            </div>
            <div class="mt-4">
              <div v-if="address.default">
                <button
                    class="bg-green-500 w-48 text-xs font-bold text-white px-4 py-2 rounded-full"
                >
                  Dirección por defecto
                </button>
              </div>
              <div v-else>
                <button
                    class="bg-gray-300  w-48 px-4 py-2 rounded-full text-xs font-bold"
                    @click="setAsDefault(address)"
                >
                  Selecionar por defecto
                </button>
              </div>
            </div>
            <div class="">
              <div class="mt-4">
                <button
                    class="bg-blue-500 w-48 text-xs font-bold text-white px-4 py-2 rounded-full"
                    href="#edit-address-modal"
                    uk-toggle
                    @click="setEditMode(address)"
                >editar</button>

              </div>
              <div class="mt-4">
                <button
                    class="bg-red-500 w-48 text-xs font-bold text-white px-4 py-2 rounded-full"
                    href="#delete-address-modal"
                    uk-toggle
                    @click="setEditMode(address)"
                >Eliminar</button>
              </div>
            </div>

          </div>
          <div class="w-2/3  ml-6 p-4">
            <GoogleMap
                v-if="address.lat && address.lng"
                :mapConfig="{
                center: { lat: address.lat, lng: address.lng },
                zoom: 13,
              }"
            >
              <template slot-scope="{ google, map }">
                <GoogleMapMarker
                    :marker="{
                    id: address.id,
                    position: { lat: address.lat, lng: address.lng },
                  }"
                    :google="google"
                    :map="map"
                />
              </template>
            </GoogleMap>
          </div>

        </div>
      </div>
    </div>

    <div class="uk-margin-small-top uk-hidden@m">
      <div class="" uk-grid>
        <div
          v-for="address in addresses"
          :key="address.id"
          class="uk-width-1-1"
        >
          <div
            class="uk-card uk-card-default uk-padding-small uk-border-rounded"
          >
            <div class="uk-text-bold uk-text-large">
              {{ address.first_name }} {{ address.last_name }}
            </div>
            <div>
              <span class="uk-text-bold">Teléfono:</span>
              {{ address.phone }}
            </div>
            <div>
              <span class="uk-text-bold">Dirección:</span>
              {{ address.address }}, Zona
              {{ address.zone }}
            </div>
            <div>
              {{ address.city.name }}, {{ address.city.state.name }},
              {{ address.city.state.country.spanish }}
            </div>

            <GoogleMap
              class="uk-margin-top"
              v-if="address.lat && address.lng"
              :mapConfig="{
                center: { lat: address.lat, lng: address.lng },
                zoom: 13,
              }"
            >
              <template slot-scope="{ google, map }">
                <GoogleMapMarker
                  :marker="{
                    id: address.id,
                    position: { lat: address.lat, lng: address.lng },
                  }"
                  :google="google"
                  :map="map"
                />
              </template>
            </GoogleMap>

            <div class="uk-text-center uk-margin-top">
              <div class="uk-button-group uk-border-rounded uk-overflow-hidden">
                <button
                  class="uk-button uk-button-secondary"
                  uk-icon="pencil"
                  href="#edit-address-modal"
                  uk-toggle
                  @click="setEditMode(address)"
                ></button>
                <button
                  class="uk-button uk-button-primary"
                  uk-icon="trash"
                  href="#delete-address-modal"
                  uk-toggle
                  @click="setEditMode(address)"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoadingAddresses" class="uk-text-center">
      <div uk-spinner></div>
    </div>

    <div id="edit-address-modal" class="uk-flex-top" uk-modal="bg-close: false">
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Agregar</span>
          <span v-else>Editar</span>
          dirección
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider
            name="Nombre"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Nombres *</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombre"
              v-model="model.first_name"
              autocomplete="off"

            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Nombre"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Apellidos *</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Nombre"
              v-model="model.last_name"
              autocomplete="off"

            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Teléfono"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Teléfono *</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Teléfono"
              v-model="model.phone"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider name="País" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">País *</div>
            <select
              :disabled="isLoadingLocations"
              name="País"
              v-model="model.country_id"
              class="uk-select uk-border-rounded"
              autocomplete="off"
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country.id"
              >
                {{ country.spanish }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>
          <ValidationProvider
            name="Departamento"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Departamento *</div>
            <select
              :disabled="isLoadingLocations"
              name="Departamento"
              v-model="model.state_id"
              class="uk-select uk-border-rounded"
            >
              <option
                v-for="(state, index) in states"
                :key="index"
                :value="state.id"
              >
                {{ state.name }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>
          <ValidationProvider
            name="Ciudad"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Ciudad *</div>
            <select
              :disabled="isLoadingLocations"
              name="Ciudad"
              v-model="model.city_id"
              class="uk-select uk-border-rounded"
            >
              <option
                v-for="(city, index) in cities"
                :key="index"
                :value="city.id"
              >
                {{ city.name }}
              </option>
            </select>
            <div>{{ errors.length !== 0 ? errors[0] : "" }}</div>
          </ValidationProvider>

          <ValidationProvider
            name="Dirección"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="uk-margin-top">Dirección *</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Dirección"
              v-model="model.address"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider name="Zona" rules="required" v-slot="{ errors }">
            <div class="uk-margin-top">Zona *</div>
            <input
              class="uk-input uk-border-rounded"
              type="text"
              name="Zona"
              v-model="model.zone"
              autocomplete="off"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>

          <GoogleMap
            :editable="true"
            :draggable="true"
            v-model="model.position"
            class="uk-margin-large-top uk-width-1-1"
          />

          <div class="uk-text-center uk-margin-top">
            <button
              class="uk-button uk-button-primary uk-border-rounded"
              :disabled="invalid || isLoading"
              @click="mode === 'new' ? store() : update()"
            >
              <span v-if="isLoading" uk-spinner></span>
              <span v-else>
                <span v-if="mode === 'new'">Agregar</span>
                <span v-else>Editar</span>
                dirección
              </span>
            </button>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      id="delete-address-modal"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedAddress"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar {{ selectedAddress.name }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="destroy"
            :disabled="isLoading"
          >
            <span v-if="isLoading" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import GoogleMap from "../Utils/GoogleMap";
import GoogleMapMarker from "../Utils/GoogleMapMarker";

const blankModel = {
  first_name: null,
  last_name: null,
  address: null,
  city: null,
  state: null,
  country: null,
  country_id: null,
  state_id: null,
  city_id: null,
  phone: null,
  zone: null,
  position: {
    lat: null,
    lng: null,
  },
};

export default {
  name: "AddressCrud",

  components: {
    GoogleMap,
    GoogleMapMarker,
  },

  data() {
    return {
      mode: "new",
      model: this._.cloneDeep(blankModel),
      addresses: [],
      isLoadingAddresses: false,
      selectedAddress: null,
      isLoading: false,
      isLoadingLocations: false,
      countries: [],
      states: [],
      cities: [],
    };
  },

  mounted() {
    this.fetchAddresses();
    this.fetchCountries();
  },

  computed: {
    selectedCountry() {
      return this.model.country_id;
    },
    selectedState() {
      return this.model.state_id;
    },
    selectedCity() {
      return this.model.city_id;
    },
  },

  methods: {
    fetchCountries() {
      this.isLoadingLocations = true;
      this.axios
        .get("/countries")
        .then(({ data: { countries } }) => {
          this.countries = countries;
        })
        .finally(() => {
          this.isLoadingLocations = false;
        });
    },
    fetchStates(countryId) {
      this.isLoadingLocations = true;
      this.axios
        .get("/states", {
          params: {
            countryId,
          },
        })
        .then(({ data: { states } }) => {
          this.states = states;
        })
        .finally(() => {
          this.isLoadingLocations = false;
        });
    },
    fetchCities(stateId) {
      this.isLoadingLocations = true;
      this.axios
        .get("/cities", {
          params: {
            stateId,
          },
        })
        .then(({ data: { cities } }) => {
          this.cities = cities;
        })
        .finally(() => {
          this.isLoadingLocations = false;
        });
    },
    resetModel() {
      this.model = this._.cloneDeep(blankModel);
    },
    fetchAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/addresses")
        .then(({ data }) => {
          this.addresses = data.addresses;
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    setNewMode() {
      this.mode = "new";
      this.selectedAddress = null;
      this.resetModel();
    },
    setEditMode(address) {
      this.mode = "edit";
      this.selectedAddress = address;
      this.model.first_name = address.first_name;
      this.model.last_name = address.last_name;
      this.model.address = address.address;
      this.model.zone = address.zone;
      this.model.city_id = address.city.id;
      this.model.state_id = address.city.state.id;
      this.model.country_id = address.city.state.country.id;
      this.model.phone = address.phone;
      this.model.position.lat = address.lat;
      this.model.position.lng = address.lng;
    },
    store() {
      this.isLoading = true;
      this.axios
        .post("/addresses", this.model)
        .then(() => {
          this.fetchAddresses();
          UIkit.modal("#edit-address-modal").hide();
          this.resetModel();
          UIkit.notification({
            message: 'Nueva Direccion.',
            pos: "bottom-left",
          });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
          console.log(this.model);
        });
    },
    update() {
      this.isLoading = true;
      this.axios
        .put("/addresses/" + this.selectedAddress.id.toString(), this.model)
        .then(() => {
          this.fetchAddresses();
          UIkit.modal("#edit-address-modal").hide();
          UIkit.notification({
            message: 'Direccion Actualizada.',
            pos: "bottom-left",
          });
          this.resetModel();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    destroy() {
      this.isLoading = true;
      this.axios
        .delete("/addresses/" + this.selectedAddress.id.toString())
        .then(() => {
          this.fetchAddresses();
          UIkit.modal("#delete-address-modal").hide();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    setAsDefault(address) {
      this.isLoading = true;
      this.axios
        .put("/addresses/" + address.id.toString() + "/set-as-default")
        .then(() => {
          this.fetchAddresses();
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  watch: {
    selectedCountry(countryId) {
      if (countryId) {
        this.fetchStates(countryId);
      }
    },
    selectedState(stateId) {
      if (stateId) {
        this.fetchCities(stateId);
      }
    },
  },
};
</script>
